export default [{
    path: '/visitor/info',
    meta: {requireAuth: true, roles: ['V']},
    component: () => import( '@/views/powerInfo/Info.vue'),
    redirect: '/visitor/info/powerInfo',
    children: [{
        path: '/visitor/info/powerInfo',
        meta: {requireAuth: true, roles: ['V']},
        component: () => import( '@/views/powerInfo/powerInfoMain.vue'),
        redirect: '/visitor/info/powerInfo/powerData',
        children: [{
            path: "/visitor/info/powerInfo/powerData",
            meta: {requireAuth: true, roles: ['V']},
            component: () => import( '@/views/powerInfo/powerData.vue'),
        }, {
            path: "/visitor/info/powerInfo/details",
            meta: {requireAuth: true, roles: ['V']},
            component: () => import( '@/views/powerInfo/details.vue'),
        }, {
            path: "/visitor/info/powerInfo/layoutStatic",
            meta: {requireAuth: true, roles: ['V']},
            component: () => import( '@/views/powerInfo/layoutStatic.vue'),
        }]
    }, {
        path: '/visitor/info/distribution',
        meta: {requireAuth: true, roles: ['V']},
        component: () => import( '@/views/plantLocation/distribution.vue')
    }]
}]